export class TenantVisit {
  createdDt: Date;
  creatorUid: string;
  key: number;
  lastDt: Date;
  lastUid: string;
  leaseKey: number;
  notes: string;
  visitDt: Date;
  visitType: string;

  static toTenantVisit(json): TenantVisit {
    return {
      createdDt: json.createdDt ? new Date(json.createdDt) : "",
      creatorUid: json.creatorUid,
      key: json.key,
      lastDt: json.lastDt ? new Date(json.lastDt) : "",
      lastUid: json.lastUid,
      leaseKey: json.leaseKey,
      notes: json.notes,
      visitDt: json.visitDt ? new Date(json.visitDt) : "",
      visitType: json.visitType,
    } as TenantVisit;
  }
}

export class AmendmentApproval {
  leaseKey: number;
  amendmentHmy: number;
  leaseID: string;
  tenantName: string;
  leaseType: string;
  rentType: string;
  amendmentType: string;
  statusDesc: string;
  leaseSf: string;
  totalLeaseableSf: string;
  depositAmt: string;
  startDt: string;
  endDt: string;
  amendmentTerm: string;
  amendmentTermMonths: string;
  isMTM: boolean;
  activatedUID: string;
  amendmentDt: string;
  lastUID: string;
  lastDt: string;
  notes: string;
  billingAddress: string;
  propertyID: string;

  // approval stuff
  basicInfoApprovedKey: number;
  basicInfoApprovedUID: string;
  basicInfoApprovedDt: string;
  chargeScheduleApprovedKey: number;
  chargeScheduleApprovedUID: string;
  chargeScheduleApprovedDt: string;
  recoveryApprovedKey: number;
  recoveryApprovedUID: string;
  recoveryApprovedDt: string;
  optionsApprovedKey: number;
  optionsApprovedUID: string;
  optionsApprovedDt: string;
  securityDepositApprovedKey: number;
  securityDepositApprovedUID: string;
  securityDepositApprovedDt: string;
  straightLineApprovedKey: number;
  straightLineApprovedUID: string;
  straightLineApprovedDt: string;
  leasingCostsApprovedKey: number;
  leasingCostsApprovedUID: string;
  leasingCostsApprovedDt: string;

  charges: AmendmentCharge[];
  deposits: AmendmentDeposit[];
  recoveries: AmendmentRecovery[];
  options: AmendmentOption[];
  units: AmendmentUnit[];

  constructor() { }
}

export class AmendmentCharge {
  chargeCodeKey: number;
  chargeCodeId: string;
  chargeCodeDesc: string;
  invoiceFrequency: string;
  estimateFrequency: string;
  prorateDesc: string;
  startDt: string;
  endDt: string;
  chargeTerm: string;
  chargeFilterType: string;
  chargeAmt: string;
  contractArea: string;
  constructor() { }
}

export class AmendmentDeposit {
  chargeCodeKey: number;
  chargeCodeId: string;
  chargeCodeDesc: string;
  invoiceFrequency: string;
  estimateFrequency: string;
  prorateDesc: string;
  startDt: string;
  endDt: string;
  chargeTerm: string;
  chargeFilterType: string;
  chargeAmt: string;

  constructor() { }
}

export class AmendmentRecovery {
  chargeCodeKey: number;
  chargeCodeId: string;
  chargeCodeDesc: string;
  invoiceFrequency: string;
  estimateFrequency: string;
  prorateDesc: string;
  startDt: string;
  endDt: string;
  chargeTerm: string;
  chargeFilterType: string;
  chargeAmt: string;
  stopAmt: string;
  capAmt: string;
  fixedSharePct: string;
  expensePool: string;

  constructor() { }
}

export class AmendmentOption {
  optionType: string;
  statusDesc: string;
  expirationDt: string;
  optionNotes: string;

  constructor() { }
}

export class AmendmentUnit {
  leaseID: string;
  propertyID: string;
  buildingID: string;
  unitID: string;
  unitDesc: string;
  leaseableSf: string;
  contractArea: string;
  startDt: string;
  endDt: string;

  constructor() { }
}

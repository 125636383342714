
import { catchError, map } from 'rxjs/operators';
import { FollowUp } from './followUp';

import { throwError as observableThrowError, forkJoin, Observable, Subject, ReplaySubject } from 'rxjs';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as moment from 'moment';
import { Lease, LeaseAbstract, LeaseAmendment, LeaseCertificate, LeaseCharge, LeaseContractor, LeaseEntry, LeaseLedger, LeaseOption, OpenItem, Utility, AmendmentApproval, ContactListItem } from '.';
import { AlertService } from '../core/alert.service';
import { Unit } from '../property';
import { TenantVisit } from './tenantVisit';

import { Contact } from '../contact/contact';
import { SaveService } from '../shared/save.service';
import { ChangePacket } from '../shared/changePacket';
import { ContactEditDialogComponent } from '../shared/contact-edit-dialog/contact-edit-dialog.component';
import { ContactSearchComponent } from '../shared/contact-search/contact-search.component';
import { EventInput } from '@fullcalendar/core';
import { Contractor } from '../misc/contractor/contractor';
import * as XLSX from '@sheet/core';
import { UserService } from '../core/user.service';
import { Lookup } from '../shared/select/lookup';
import { Vendor } from '../misc/vendor/vendor';

@Injectable()
export class LeaseService {
  private leaseUrlCloud = environment.cubCloudUrl + 'leases';
  private leaseContractorCloudUrl = environment.cubCloudUrl + 'leaseContractors';
  private leaseUtilUrl = environment.cubCloudUrl + 'leaseUtilities';
  private leaseEntryUrl = environment.cubCloudUrl + 'leaseEntry';

  private leaseList = new ReplaySubject<Lease[]>(1);
  leases$ = this.leaseList.asObservable(); // $ is connvention to indicate observable
  private termLeaseList = new ReplaySubject<Lease[]>(1);
  termLeases$ = this.termLeaseList.asObservable(); // $ is connvention to indicate observable
  private selectedId = new ReplaySubject<string>(1);
  selectedId$ = this.selectedId.asObservable();
  private listSettings = new ReplaySubject<any>(1);
  listSettings$ = this.listSettings.asObservable();

  public terminatedLeasesLoaded = false;
  private leaseContactUpdated = new Subject<ContactListItem>();
  leaseContactUpdated$ = this.leaseContactUpdated.asObservable();
  private savingLeaseContact = new Subject<boolean>();
  savingLeaseContact$ = this.savingLeaseContact.asObservable();

  constructor(
    private dialog: MatDialog,
    private saveService: SaveService,
    private userService: UserService,
    private http: HttpClient,
    private alertService: AlertService) {
    this.getLeases();
  }

  getLeaseUtility(id: string): Observable<Utility> {
    const url = `${this.leaseUtilUrl}/${id}`;
    let list = this.http.get<Utility>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
    return list;
  }

  saveUtility(utility: Utility) {
    const url = `${this.leaseUtilUrl}`;
    return this.http.post<Utility>(url, JSON.stringify(utility), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).pipe(catchError(err => this.handleError(err, this.alertService)));
  }

  removeUtility(id: number): Promise<number> {
    const url = `${this.leaseUtilUrl}/${id}`;
    return this.http
      .delete(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
      .toPromise()
      .then(() => id);
  }

  getLeaseEntry(id: string): Observable<LeaseEntry> {
    const url = `${this.leaseEntryUrl}/${id}`;
    let list = this.http.get<LeaseEntry>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
    return list;
  }

  // this isn't used
  saveLeaseEntry(leaseEntry: LeaseEntry) {
    const url = `${this.leaseEntryUrl}`;
    return this.http.post<LeaseEntry>(url, JSON.stringify(leaseEntry), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).pipe(catchError(err => this.handleError(err, this.alertService)));
  }

  sendToYardi(leaseEntry: LeaseEntry) {
    const url = `${this.leaseEntryUrl}/sendYardi`;
    return this.http.post<LeaseEntry>(url, JSON.stringify(leaseEntry), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).pipe(catchError(err => this.handleError(err, this.alertService)));
  }

  deleteLeaseEntry(leaseEntry: LeaseEntry) {
    const url = `${this.leaseEntryUrl}/delete`;
    return this.http.post<LeaseEntry>(url, JSON.stringify(leaseEntry), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).pipe(catchError(err => this.handleError(err, this.alertService)));
  }

  getLeaseCharges(id: string): Observable<LeaseCharge[]> {
    const url = `${this.leaseUrlCloud}/${id}/charges`;
    let list = this.http.get<LeaseCharge[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
    return list;
  }

  getLeaseOpenItems(id: string): Observable<OpenItem[]> {
    const url = `${this.leaseUrlCloud}/${id}/openitems`;
    let list = this.http.get<OpenItem[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
    return list;
  }

  getLeaseOptions(id: string): Observable<LeaseOption[]> {
    const url = `${this.leaseUrlCloud}/${id}/options`;
    return this.http.get<LeaseOption[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getLeaseAmendments(id: string): Observable<LeaseAmendment[]> {
    const url = `${this.leaseUrlCloud}/${id}/amendments`;
    return this.http.get<LeaseAmendment[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getLeaseAbstract(id: string): Observable<LeaseAbstract[]> {
    const url = `${this.leaseUrlCloud}/${id}/abstract`;
    return this.http.get<LeaseAbstract[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getLeaseCertificates(id: string): Observable<LeaseCertificate[]> {
    const url = `${this.leaseUrlCloud}/${id}/certificates`;
    return this.http.get<LeaseCertificate[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getLeaseRelationships(id: string): Observable<Contact[]> {
    const url = `${this.leaseUrlCloud}/${id}/relationships`;
    return this.http.get<Contact[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getYardiLeaseRelationships(key: number): Observable<Contact[]> {
    const url = `${this.leaseUrlCloud}/${key}/yardiRelationships`;
    return this.http.get<Contact[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getLeaseUnits(id: string): Observable<Unit[]> {
    const url = `${this.leaseUrlCloud}/${id}/units`;
    return this.http.get<Unit[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getChargesForLeases(keys: number[]): Observable<LeaseCharge[]> {
    const url = `${this.leaseUrlCloud}/charges`;
    return this.http.post<LeaseCharge[]>(url, keys, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).pipe(catchError(err => this.handleError(err, this.alertService)));
  }

  getOptionsForLeases(keys: number[]): Observable<LeaseOption[]> {
    const url = `${this.leaseUrlCloud}/options`;
    return this.http.post<LeaseOption[]>(url, keys, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).pipe(catchError(err => this.handleError(err, this.alertService)));
  }

  getTenantVisits(leaseID: string): Observable<TenantVisit[]> {
    const url = `${this.leaseUrlCloud}/${leaseID}/visits`;
    return this.http.get<TenantVisit[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).pipe(map(visits => visits.map(f => TenantVisit.toTenantVisit(f))));
  }

  getFollowUps(keys: number[]): Observable<FollowUp[]> {
    const url = `${this.leaseUrlCloud}/followUps`;
    return this.http.post<FollowUp[]>(url, keys, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).pipe(map(followUps => followUps.map(f => FollowUp.toFollowUp(f))));
  }

  getTenantVisitsForLeases(keys: number[]): Observable<TenantVisit[]> {
    const url = `${this.leaseUrlCloud}/visits`;
    return this.http.post<TenantVisit[]>(url, keys, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).pipe(map(visits => visits.map(f => TenantVisit.toTenantVisit(f))));
  }

  getContactsForLeases(leaseKeys: number[]): Observable<Lease[]> {
    const url = `${this.leaseUrlCloud}/contacts`;
    return this.http.post<Lease[]>(url, leaseKeys, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getLegalEntity(id: number): Observable<any> {
    const url = `${this.leaseUrlCloud}/certificates/entity/${id}`;
    return this.http.get<any>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  saveCertificate(certificate: LeaseCertificate) {
    const url = `${this.leaseUrlCloud}/certificates/save`;
    return this.http.post<LeaseCertificate>(url, JSON.stringify(certificate), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).pipe(catchError(err => this.handleError(err, this.alertService)));
  }

  saveFollowUp(id: string, followUp: FollowUp): Observable<FollowUp> {
    const url = `${this.leaseUrlCloud}/${id}/followUps/${followUp.id}`;
    return this.http.post<FollowUp>(url, followUp, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).pipe(catchError(err => this.handleError(err, this.alertService)));
  }

  deleteCertificate(id: number): Promise<number> {
    const url = `${this.leaseUrlCloud}/certificates/delete/${id}`;
    return this.http
      .delete(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false })
      .toPromise()
      .then(() => id);
  }

  saveTenantVisit(leaseID: string, tenantVisit: TenantVisit): Observable<TenantVisit> {
    const url = `${this.leaseUrlCloud}/${leaseID}/visits/${tenantVisit.key}`;
    return this.http.post<TenantVisit>(url, tenantVisit, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).pipe(catchError(err => this.handleError(err, this.alertService)));
  }

  deleteTenantVisit(leaseID: string, tenantVisit: TenantVisit) {
    const url = `${this.leaseUrlCloud}/${leaseID}/visits/${tenantVisit.key}`;
    return this.http.delete<boolean>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).pipe(catchError(err => this.handleError(err, this.alertService)));
  }

  getLeaseAmendmentApproval(id: string, hmy: string): Observable<AmendmentApproval> {
    const url = `${this.leaseUrlCloud}/${id}/amendment/${hmy}`;
    return this.http.get<AmendmentApproval>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  saveAmendmentApproval(approval: AmendmentApproval) {
    const url = `${this.leaseUrlCloud}/${approval.leaseID}/amendment/${approval.amendmentHmy}/save`;
    return this.http.post<any>(url, JSON.stringify(approval), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).pipe(catchError(err => this.handleError(err, this.alertService)));
  }

  getAllContractors(): Observable<LeaseContractor[]> {
    const url = `${this.leaseContractorCloudUrl}`;
    return this.http.get<LeaseContractor[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  addRelationship(id: string, typeKey: number, pcKey: number): Observable<boolean> {
    const url = `${this.leaseUrlCloud}/${id}/relationships/${pcKey}/add/${typeKey}`;
    return this.http.post<boolean>(url, '', { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  removeRelationship(id: string, typeKey: number, pcKey: number): Observable<boolean> {
    const url = `${this.leaseUrlCloud}/${id}/relationships/${pcKey}/delete/${typeKey}`;
    return this.http.post<boolean>(url, '', { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getLeases(): void {
    const url = `${this.leaseUrlCloud}`;
    this.http.get<Lease[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).subscribe(leases => {
      this.leaseList.next(leases);
    });
  }

  getTerminatedLeases(): Observable<Lease[]> {
    const url = `${this.leaseUrlCloud}/terminated`;
    return this.http.get<Lease[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).pipe(map(leases => {
      this.termLeaseList.next(leases);
      this.terminatedLeasesLoaded = true;
      return leases;
    }));
  }

  getLease(id: string): Observable<Lease> {
    const url = `${this.leaseUrlCloud}/${id}`;
    return this.http.get<Lease>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getLeaseLedger(id: string): Observable<LeaseLedger[]> {
    const url = `${this.leaseUrlCloud}/${id}/ledger`;
    return this.http.get<LeaseLedger[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  createTenantFolder(leaseID: string): Observable<string> {
    const url = `${this.leaseUrlCloud}/${leaseID}/createTenantFolder`;
    return this.http.post<string>(url, '', { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).pipe(catchError(err => this.handleError(err, this.alertService)));
  }

  syncNewYardiObjects() {
    const url = `${this.leaseUrlCloud}/syncNewYardiObjects`;
    return this.http.post<string>(url, '', { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).pipe(catchError(err => this.handleError(err, this.alertService)));
  }

  getCalendarDataForLeases(leases: Lease[]): Observable<any[]> {
    let fjItems: Observable<any[]>[] = [];

    if (leases.filter(l => l !== undefined).length > 0) {
      let fu = this.getFollowUps(leases.map(l => l.leaseKey));
      fjItems.push(fu);
      let ch = this.getChargesForLeases(leases.map(l => l.leaseKey));
      fjItems.push(ch);
      let op = this.getOptionsForLeases(leases.map(l => l.leaseKey));
      fjItems.push(op);
    }
    return forkJoin(fjItems);
  }

  processDataIntoEvents(data: any, leases: Lease[]) {
    let events: EventInput[] = [];

    // load calendar events from leases
    events = events.concat(leases.map(l => ({ id: 'leaseTermDt:' + l.leaseID, classNames: ['leaseTermDt'], title: 'Termination: ' + l.tenantName, start: moment(l.terminationDt, 'MM/DD/YYYY').toDate(), allDay: true })));

    // load calendar events from observables
    let followUps = data[0];
    events = events.concat(followUps.map(fu => ({ id: 'nextFollow:' + fu.id, classNames: ['nextFollow'], title: 'Next Follow Up: ' + this.getTenantName(leases.filter(l => l.leaseKey === fu.leaseKey)), start: moment(fu.nextFollowUpDt, 'MM/DD/YYYY').toDate(), allDay: true })));
    let leaseCharges = data[1];
    events = events.concat(leaseCharges.map(ch => ({ id: 'charge:' + ch.chargeCodeKey, classNames: ['charge'], title: 'Lease Charge: ' + this.getTenantName(leases.filter(l => l.leaseKey === ch.leaseKey)), start: moment(ch.startDt, 'MM/DD/YYYY').toDate(), allDay: true })));
    let leaseOptions = data[2];
    events = events.concat(leaseOptions.map(op => ({ id: 'option:' + op.optionKey, classNames: ['option'], title: 'Lease Option: ' + this.getTenantName(leases.filter(l => l.leaseKey === op.leaseKey)), start: moment(op.startDt, 'MM/DD/YYYY').toDate(), allDay: true })));

    return events;
  }

  getTenantName(leases: Lease[]): string {
    if (leases.length > 0) {
      return leases[0].tenantName;
    } else {
      return '';
    }
  }

  private handleError(error: HttpErrorResponse | any, alertService: AlertService) {
    let errMsg: string;
    let publicMsg = 'Something bad happened; please contact MIS or try again later.';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errMsg = error.error.message;
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errMsg = error.error;
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    alertService.error('An error occurred: ' + publicMsg);
    // return an observable with a user-facing error message
    return observableThrowError(publicMsg);
  }

  // contact related stuff
  changePC(lease: Lease, contactType: string, contactTypeKey: number): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '500px';
    dialogConfig.position = { top: '200px' };
    const dialogRef = this.dialog.open(ContactSearchComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.status === 'new') {
        this.addTenantContact(lease, contactType, contactTypeKey);
      } else if (result.status === 'selected') {
        this.saveLeaseContactKey(lease, contactType, result.contact, contactTypeKey);
      }
    });
  }

  addTenantContact(lease: Lease, type: string, contactTypeKey: number) {
    let newContact = new Contact();
    newContact.isPerson = true;
    newContact.isEmployee = false;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '700px';
    dialogConfig.position = { top: '200px' };
    dialogConfig.data = {
      contact: newContact
    };
    const dialogRef = this.dialog.open(ContactEditDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(updatedContact => {
      this.saveLeaseContactKey(lease, type, updatedContact, contactTypeKey);
    });
  }

  saveLeaseContactKey(lease: Lease, type: string, updatedContact: Contact, contactTypeKey: number) {
    let fieldName = '';
    let fieldLabel = '';
    let fieldValue = '';
    let tiedToLease = false;
    switch (type) {
      case 'Re Exec':
        fieldName = 'REAL_ESTATE_EXEC_PC_KEY';
        fieldLabel = 'Corporate Real Estate Exec';
        fieldValue = lease.reExecName;
        tiedToLease = true;
        break;
      case 'On Site Mgr':
        fieldName = 'ON_SITE_MANAGER_PC_KEY';
        fieldLabel = 'On-Site Mgr';
        fieldValue = lease.onSiteMgrName;
        tiedToLease = true;
        break;
      case 'Notice Contact 1':
        fieldName = 'NOTICES_CONTACT_PC_KEY';
        fieldLabel = 'Notices Contact (1)';
        fieldValue = lease.noticesContactName;
        tiedToLease = true;
        break;
      case 'Notice Contact 2':
        fieldName = 'NOTICES_CONTACT2_PC_KEY';
        fieldLabel = 'Notices Contact (2)';
        fieldValue = lease.noticesContact2Name;
        tiedToLease = true;
        break;
      case 'Notice Contact 3':
        fieldName = 'NOTICES_CONTACT3_PC_KEY';
        fieldLabel = 'Notices Contact (3)';
        fieldValue = lease.noticesContact3Name;
        tiedToLease = true;
        break;
      case 'Emergency Contact 1':
        fieldName = 'XREF;RELATIONSHIP;1052;LEASE_KEY;PC_KEY';
        fieldLabel = 'Emergency Contact (1)';
        tiedToLease = false;
        break;
      case 'Emergency Contact 2':
        fieldName = 'XREF;RELATIONSHIP;1053;LEASE_KEY;PC_KEY';
        fieldLabel = 'Emergency Contact (2)';
        tiedToLease = false;
        break;
      case 'Emergency Contact 3':
        fieldName = 'XREF;RELATIONSHIP;1054;LEASE_KEY;PC_KEY';
        fieldLabel = 'Emergency Contact (3)';
        tiedToLease = false;
        break;
      case 'Insurance Manager':
        fieldName = 'XREF;RELATIONSHIP;1037;LEASE_KEY;PC_KEY';
        fieldLabel = 'Insurance Manager';
        tiedToLease = false;
        break;
      case 'Tenant Accountant':
        fieldName = 'XREF;RELATIONSHIP;1058;LEASE_KEY;PC_KEY';
        fieldLabel = 'Tenant Accountant';
        tiedToLease = false;
        break;
      case 'Tenant Regional Manager':
        fieldName = 'XREF;RELATIONSHIP;1062;LEASE_KEY;PC_KEY';
        fieldLabel = 'Tenant Regional Manager';
        tiedToLease = false;
        break;
      case 'Day to Day':
        fieldName = 'XREF;RELATIONSHIP;1055;LEASE_KEY;PC_KEY';
        fieldLabel = 'Day to Day';
        tiedToLease = false;
        break;
      case 'Other':
        fieldName = 'XREF;RELATIONSHIP;1056;LEASE_KEY;PC_KEY';
        fieldLabel = 'Other';
        tiedToLease = false;
        break;
      default:
        break;
    }
    if (updatedContact !== null) {
      // update contact
      if (tiedToLease) {
        this.saveLeaseField(lease, updatedContact, type, contactTypeKey, fieldName, fieldLabel, fieldValue, updatedContact.pcKey.toString(), updatedContact.firstName + ' ' + updatedContact.lastName, 'int', 32);
      } else {
        // insert new row (can have multiple records for this relationship type)
        this.addRelationship(lease.leaseID, contactTypeKey, updatedContact.pcKey).subscribe(b => {
          this.leaseContactUpdated.next(new ContactListItem(type, contactTypeKey, updatedContact, lease.leaseKey));
          this.savingLeaseContact.next(false);
        });
      }
    } else {
      // deleting contact
      this.saveLeaseField(lease, updatedContact, type, contactTypeKey, fieldName, fieldLabel, fieldValue, '', '', 'int', 32);
    }
  }

  saveLeaseField(lease: Lease, contact: Contact, contactType: string, contactTypeKey: number, fieldName: string, label: string, origValue: string, newValue: string, valueToLog: string, dataType: string, dataLength: number): void {
    let changePacket: ChangePacket;
    changePacket = new ChangePacket('Lease', fieldName, label, lease.leaseKey, origValue, newValue, valueToLog, dataType, dataLength, false);
    this.savingLeaseContact.next(true);
    this.saveService
      .saveChange(changePacket)
      .then(returnVal => {
        this.leaseContactUpdated.next(new ContactListItem(contactType, contactTypeKey, contact, lease.leaseKey));
        this.savingLeaseContact.next(false);
      })
      .catch(() => {
        alert('An error occured saving the lease data');
      });
  }

  getFieldNameFromDesc(desc: string): string {
    let name = '';
    switch (desc) {
      case 'RE Exec':
      case 'Re Exec':
        name = 'reExecKey';
        break;
      case 'On Site Mgr':
        name = 'onSiteMgrKey';
        break;
      case 'Notice Contact 1':
        name = 'noticesContactKey';
        break;
      case 'Notice Contact 2':
        name = 'noticesContact2Key';
        break;
      case 'Notice Contact 3':
        name = 'noticesContact3Key';
        break;
      default:
        break;
    }
    return name;
  }

  getOfficePhone(c: Contact): string {
    let phone = c.phoneNumbers.filter(t => t.typeKey === 1 || t.typeKey === 3).sort((a, b) => (a.phoneKey > b.phoneKey ? -1 : 1))[0];
    return (phone !== undefined ? phone.number : '');
  }

  getCellPhone(c: Contact): string {
    let phone = c.phoneNumbers.filter(t => t.typeKey === 5)[0];
    return (phone !== undefined ? phone.number : '');
  }

  getEmail(c: Contact): string {
    let email = c.emailAddresses[0];
    return (email !== undefined ? email.email : '');
  }

  downloadWorksheet(leases: Lease[], leaseContacts: Lease[], utilities: Utility[], contractors: Vendor[], trades: Lookup[]) {
    // two lease arrays here seems redundant, but the leaseContacts "lease" array is really an array of contacts for each lease.

    const newWorkbook = XLSX.utils.book_new();
    leases.forEach(lease => {
      // basic prop info
      let propHeaders = ['Property Address', 'County', 'Property Id', 'Sq Ft', 'Gate Code', 'Door (Gas) Code', 'Fire Service #', 'Police Service #'];
      let worksheet = XLSX.utils.aoa_to_sheet([propHeaders]);
      let propRef = XLSX.utils.decode_range(worksheet['!ref']);
      let propHeaderRange = XLSX.utils.encode_range({
        s: { // starting cell
          r: propRef.e.r, // last row up to this point
          c: 0,
        },
        e: {
          r: propRef.e.r,
          c: propHeaders.length - 1 // columns are zero-indexed
        }
      });
      XLSX.utils.sheet_set_range_style(worksheet, propHeaderRange, { bold: true }); // fill out the style object as you desire
      XLSX.utils.sheet_add_aoa(worksheet, [
        [
          lease.propertyDesc + ', ' + lease.city + ', ' + lease.stateId + ', ' + lease.zipCode,
          lease.county,
          lease.propertyID,
          lease.leaseSF,
          lease.gate,
          lease.meterDoor,
          lease.fireNumber,
          lease.policeNumber
        ]
      ], { origin: -1 });


      // contacts listing
      let contactHeaders = ['Type', 'Name', 'Job Title', 'Work Phone', 'Cell Phone', 'Email', 'Notes'];
      XLSX.utils.sheet_add_aoa(worksheet, [[], ['Contacts'], contactHeaders], { origin: -1 });
      let contactRef = XLSX.utils.decode_range(worksheet['!ref']);
      let contactHeaderRange = XLSX.utils.encode_range({
        s: { // starting cell
          r: contactRef.e.r - 1, // last row up to this point
          c: 0,
        },
        e: {
          r: contactRef.e.r,
          c: contactHeaders.length - 1 // columns are zero-indexed
        }
      });
      XLSX.utils.sheet_set_range_style(worksheet, contactHeaderRange, { bold: true }); // fill out the style object as you desire
      if (leaseContacts.filter(l => l.leaseKey === lease.leaseKey).length > 0) {
        let tempLease = leaseContacts.filter(l => l.leaseKey === lease.leaseKey)[0];
        XLSX.utils.sheet_add_aoa(worksheet, tempLease.relationships.map(c => [
          c.contactTypes[0].type,
          c.fullName,
          c.jobTitle,
          this.getOfficePhone(c),
          this.getCellPhone(c),
          this.getEmail(c),
          c.notes
        ]).sort((a, b) => {
          if (a[0] > b[0]) {
            return 1;
          } else {
            return -1;
          }
        }), { origin: -1 });
      }

      // util listing
      let utilHeaders = ['Utility', 'Company', 'Meter', 'Account', 'Services', 'Phone', 'GL Code', 'Notes'];
      XLSX.utils.sheet_add_aoa(worksheet, [[], ['Utilities'], utilHeaders], { origin: -1 });
      let utilityRef = XLSX.utils.decode_range(worksheet['!ref']);
      let utilityHeaderRange = XLSX.utils.encode_range({
        s: { // starting cell
          r: utilityRef.e.r - 1, // last row up to this point
          c: 0,
        },
        e: {
          r: utilityRef.e.r,
          c: utilHeaders.length - 1 // columns are zero-indexed
        }
      });
      XLSX.utils.sheet_set_range_style(worksheet, utilityHeaderRange, { bold: true }); // fill out the style object as you desire
      XLSX.utils.sheet_add_aoa(worksheet, utilities.filter(u => u.leaseKeys.findIndex(i => i === lease.leaseKey) > -1).map(u => [
        u.utilityTypeName,
        u.company,
        u.meter,
        u.account,
        u.name,
        u.phone,
        u.glCode,
        u.notes
      ]), { origin: -1 });

      // contractor listing
      let contractorHeaders = ['Trade', 'Company', 'Contact', 'Phone Number', 'Email', 'Notes', 'In Vendor Cafe', 'RCP Status'];
      XLSX.utils.sheet_add_aoa(worksheet, [[], ['Property Vendors'], contractorHeaders], { origin: -1 });
      let contractorRef = XLSX.utils.decode_range(worksheet['!ref']);
      let contractorHeaderRange = XLSX.utils.encode_range({
        s: { // starting cell
          r: contractorRef.e.r - 1, // last row up to this point
          c: 0,
        },
        e: {
          r: contractorRef.e.r,
          c: contractorHeaders.length - 1 // columns are zero-indexed
        }
      });
      XLSX.utils.sheet_set_range_style(worksheet, contractorHeaderRange, { bold: true }); // fill out the style object as you desire
      XLSX.utils.sheet_add_aoa(worksheet, contractors.map(c => [
        c.tradeDesc,
        c.vendorName,
        c.contactName,
        c.contactPhone,
        c.emailAddress,
        c.notes,
        c.inVendorCafe,
        c.rcpStatus
      ]), { origin: -1 });

      // fire alarm
      let fireAlarmHeaders = ['Monitoring Company', 'Alarm Password', 'Panel Location', 'Account #', 'Call List Number 1', 'Call List Number 2'];
      XLSX.utils.sheet_add_aoa(worksheet, [[], ['Fire Alarm Info'], fireAlarmHeaders], { origin: -1 });
      let fireAlarmRef = XLSX.utils.decode_range(worksheet['!ref']);
      let fireAlarmHeaderRange = XLSX.utils.encode_range({
        s: { // starting cell
          r: fireAlarmRef.e.r - 1, // last row up to this point
          c: 0,
        },
        e: {
          r: fireAlarmRef.e.r,
          c: fireAlarmHeaders.length - 1 // columns are zero-indexed
        }
      });
      XLSX.utils.sheet_set_range_style(worksheet, fireAlarmHeaderRange, { bold: true }); // fill out the style object as you desire
      XLSX.utils.sheet_add_aoa(worksheet, [
        [
          lease.alarmFireMonitor,
          lease.alarmFirePwd,
          lease.alarmFirePanel,
          lease.alarmFireAcct,
          lease.alarmFireCall1,
          lease.alarmFireCall2,
        ]
      ], { origin: -1 });

      // burglar alarm
      let burglarAlarmHeaders = ['Monitoring Company', 'Alarm Password', 'Panel Location', 'Account #', 'Call List Number 1', 'Call List Number 2'];
      XLSX.utils.sheet_add_aoa(worksheet, [[], ['Burglar Alarm Info'], burglarAlarmHeaders], { origin: -1 });
      let burglarAlarmRef = XLSX.utils.decode_range(worksheet['!ref']);
      let burglarAlarmHeaderRange = XLSX.utils.encode_range({
        s: { // starting cell
          r: burglarAlarmRef.e.r - 1, // last row up to this point
          c: 0,
        },
        e: {
          r: burglarAlarmRef.e.r,
          c: burglarAlarmHeaders.length - 1 // columns are zero-indexed
        }
      });
      XLSX.utils.sheet_set_range_style(worksheet, burglarAlarmHeaderRange, { bold: true }); // fill out the style object as you desire
      XLSX.utils.sheet_add_aoa(worksheet, [
        [
          lease.alarmBurglarMonitor,
          lease.alarmBurglarPwd,
          lease.alarmBurglarPanel,
          lease.alarmBurglarAcct,
          lease.alarmBurglarCall1,
          lease.alarmBurglarCall2,
        ]
      ], { origin: -1 });

      // tenant note
      let tenantNotesHeaders = ['Tenant Notes'];
      XLSX.utils.sheet_add_aoa(worksheet, [[], tenantNotesHeaders], { origin: -1 });
      let tenantNotesAlarmRef = XLSX.utils.decode_range(worksheet['!ref']);
      let tenantNotesAlarmHeaderRange = XLSX.utils.encode_range({
        s: { // starting cell
          r: tenantNotesAlarmRef.e.r, // last row up to this point
          c: 0,
        },
        e: {
          r: tenantNotesAlarmRef.e.r,
          c: tenantNotesHeaders.length - 1 // columns are zero-indexed
        }
      });
      XLSX.utils.sheet_set_range_style(worksheet, tenantNotesAlarmHeaderRange, { bold: true }); // fill out the style object as you desire
      XLSX.utils.sheet_add_aoa(worksheet, [
        [
          lease.tenantNotes
        ]
      ], { origin: -1 });

      XLSX.utils.book_append_sheet(newWorkbook, worksheet, lease.leaseID);
      worksheet['!cols'] = [{ wch: 25 }, { wch: 25 }, { wch: 25 }, { wch: 25 }, { wch: 25 }, { wch: 25 }, { wch: 25 }, { wch: 25 }];
    });
    XLSX.writeFile(newWorkbook, 'Worksheets.xlsx', { cellStyles: true });
  }
}

<div id="linksPage" style="">
  <div class="link-section" *ngFor="let linkGroup of linkList">
    <h4>
      {{ linkGroup.name }}
    </h4>
    <hr />
    <div class="link-list">
      <div *ngFor="let link of linkGroup.subMenu">
        <button
          mat-button
          *ngIf="link.external"
          (click)="goToExternalLink(link)"
        >{{ link.name }}</button>
        <button
          mat-button
          *ngIf="!link.external"
          (click)="goToInternalLink(link)"
        >{{ link.name }}</button>
      </div>
    </div>
  </div>
</div>

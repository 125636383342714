import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subject, Observable, forkJoin } from 'rxjs';
import { UserMenu } from '../../user';
import { MenuService } from '../menu.service';
import { SearchService } from '../search.service';
import { UserService } from '../user.service';
import { LoggerService } from '../logger.service';
import { Constants } from '../constants';
import { MatDialog } from '@angular/material/dialog';
import { BadDataService } from '../../shared/bad-data-ticket/bad-data.service';
import { BadDataComponent } from '../../shared/bad-data/bad-data.component';
import { BadDataTicketComponent } from '../../shared/bad-data-ticket/bad-data-ticket.component';
import { BadDataTicket, Assignee } from '../../shared/bad-data-ticket/badDataTicket';

import * as moment from 'moment';
import { faHistory, faQuestionSquare, faBars, faEllipsisHAlt, faGripHorizontal, faComments, faBullhorn, faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { User } from '../../user/user';
import { NewMenuComponent } from '../new-menu/new-menu.component';
import { NewMenuItemComponent } from '../new-menu-item/new-menu-item.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgIf, NgFor } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';


@Component({
    selector: 'cub-top-menu',
    templateUrl: './top-menu.component.html',
    styleUrls: ['./top-menu.component.scss'],
    animations: [
        trigger('showingListing', [
            state('true', style({
                transform: 'rotate(0)'
            })),
            state('false', style({
                transform: 'rotate(90deg)'
            })),
            transition('true => false', animate('500ms ease-in')),
            transition('false => true', animate('500ms ease-out'))
        ])
    ],
    standalone: true,
    imports: [MatToolbarModule, MatButtonModule, FontAwesomeModule, NgIf, MatTooltipModule, FormsModule, MatMenuModule, NgFor, MatBadgeModule, NewMenuItemComponent, NewMenuComponent]
})
export class TopMenuComponent implements OnInit {

  @Input() loggedIn: boolean;
  @Output() logoutClicked = new EventEmitter<void>();

  recents: UserMenu[] = [];
  userMenuList: UserMenu[] = [];
  logoutMenuItem: UserMenu;
  logoutMenuItemSep: UserMenu;
  user: User;
  searchTerm$ = new Subject<string>();
  showLeftMenu = true;
  leftMenuIsCollapsed = false;
  isLoading = true;
  showPointButton = false;
  powerBIWorkspaces: any[] = [];
  powerBIReports: any[] = [];
  reportsInWorkspace: any[] = [];

  faHistory = faHistory;
  faQuestionSquare = faQuestionSquare;
  faBars = faBars;
  faEllipsisHAlt = faEllipsisHAlt;
  faGripHorizontal = faGripHorizontal;
  faComments = faComments;
  faBullhorn = faBullhorn;
  faExclamationTriangle = faExclamationTriangle;

  screenWidth: number;
  showPoint = true;
  badDataDisabled = false;
  defaultBadDataTicket: BadDataTicket;
  badDataTicket: BadDataTicket;
  badDataCount = 0;
  myDetails: any;
  nextPasswordChange: Date;

  constructor(
    private searchService: SearchService,
    private userService: UserService,
    private menuService: MenuService,
    private loggerService: LoggerService,
    private badDataService: BadDataService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) {
    this.screenWidth = (window.innerWidth);

    this.searchService.setSystem('ID');
    this.searchService.searchByTerm(this.searchTerm$).subscribe(results => {
      this.searchService.mapResult(results);
    });
    this.nextPasswordChange = moment().add(180, 'days').toDate(); // init so it doesn't show right away
    this.configureDefaultBadDataTicket();
    this.router.events.subscribe((v: any) => {
      // show point button if we have a small screen and the current screen is the dashboard
      if (this.screenWidth <= Constants.ResponsiveWidthTrigger) {
        if (v.url !== undefined) {
          if (v.url === '/dashboard' || v.url === '/') {
            this.showPointButton = true;
          } else {
            this.showPointButton = false;
          }
        }
      }
    });

    this.getPowerBIReports();
    this.addLogoutMenu();
  }

  getPowerBIReports() {
    this.userService.getPowerBIGroups().subscribe(d => {  // get report groups (workspaces) first
      let groups = d.value;
      let webCalls: Observable<any>[] = [];

      groups.forEach(group => {
        this.powerBIWorkspaces.push(group);
        webCalls.push(this.userService.getPowerBIReportsInGroup(group.id));
      });
      this.powerBIWorkspaces = this.powerBIWorkspaces.sort((a, b) => a.name.localeCompare(b.name));
      forkJoin(webCalls).subscribe(groups => { // now get reports in each group
        groups.forEach(group => {
          group.value.forEach(report => {
            this.powerBIReports.push(report);
          });
        });
        // console.log('powerBIReports', this.powerBIReports);
        this.powerBIWorkspaces = this.powerBIWorkspaces.filter(w => this.getWorkspaceReportCount(w.id) > 0).sort((a, b) => a.name.localeCompare(b.name)); // remove empty workspaces
      });
    });
  }

  getWorkspaceReportCount(workspaceId: string): number {
    return this.powerBIReports.filter(r => r.datasetWorkspaceId === workspaceId).length;
  }

  getReportsInWorkspace(workspaceId: string) {
    this.reportsInWorkspace = this.powerBIReports.filter(r => r.datasetWorkspaceId === workspaceId).sort((a, b) => a.name.localeCompare(b.name));
  }

  openPowerBIReport(report: any) {
    this.loggerService.logPageVisit('open power bi report', report.name);
    window.open(report.webUrl);
  }

  addLogoutMenu() {
    this.logoutMenuItem = new UserMenu();
    this.logoutMenuItem.name = 'Logout';
    this.logoutMenuItem.desc = 'Log out from your account';
    this.logoutMenuItem.icon = 'faRightFromBracket';
    this.logoutMenuItem.method = 'logout';
    this.logoutMenuItem.external = false;
    this.logoutMenuItem.isCustom = true;
    this.logoutMenuItem.separator = false;
    this.logoutMenuItem.link = '';

    this.logoutMenuItemSep = new UserMenu();
    this.logoutMenuItemSep.separator = true;
  }

  ngOnInit() {
    this.getMenu();

    this.menuService.showingSystemMenu$.subscribe(result => {
      this.showLeftMenu = result;
    });
    this.menuService.minMaxLeftMenu$.subscribe(result => {
      this.leftMenuIsCollapsed = result;
    });
    this.menuService.showPoint$.subscribe(result => {
      this.showPoint = result;
    });
    this.menuService.badDataPayload$.subscribe(payload => {
      if (payload === null) {
        this.badDataTicket = Object.assign({}, this.defaultBadDataTicket);
      } else {
        this.badDataTicket = payload;
      }
      this.getBadDataCount();
    });
    this.setUser(JSON.parse(localStorage.getItem('user')) as User);
    this.getMyDetails();
    this.userService.userData$.subscribe((u: User) => {
      if (u != null) {
        this.setUser(u);
      }
    });
  }

  openPasswordChange() {
    window.open('https://account.activedirectory.windowsazure.com/changepassword.aspx', '_blank');
  }

  getPasswordMessage(): string {
    if (this.daysPasswordExpiresIn() <= 0) {
      return 'Your password has expired. Please change it now.';
    } else if (this.daysPasswordExpiresIn() <= 15) {
      return 'Your password expires in ' + this.daysPasswordExpiresIn() + ' days';
    } else {
      return '';
    }
  }

  getMyDetails() {
    this.userService.getMyDetails().subscribe(d => {
      this.myDetails = d;
      this.doWorkOnUser();
    });
  }

  doWorkOnUser() {
    this.nextPasswordChange = moment(this.myDetails.lastPasswordChangeDateTime).add(180, 'days').toDate();
  }

  daysPasswordExpiresIn(): number {
    return moment(this.nextPasswordChange).diff(moment(), 'days');
  }

  setUser(user: User) {
    this.user = user;
    this.downloadImage();
  }

  downloadImage() {
    if (this.user !== null) {
      this.userService.downloadImage(this.user?.accountName?.replace('CENTERPOINT-PRO\\', '')).subscribe((blob: Blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result as string;
          localStorage.setItem('userPhoto', base64data);  // Store the Base64 string in local storage
          this.getPhotoUrl();
        };
      });
    }
  }

  getPhotoUrl(): string {
    let userPhotoData = localStorage.getItem('userPhoto');
    if (userPhotoData == null)
      userPhotoData = './assets/img/noImage.png';
    return userPhotoData;
  }

  getMenu(): void {
    this.userService.userMenu$.subscribe((menu) => {
      if (menu != null) {
        if (menu.subMenu.length > 4) {
          this.recents = menu.subMenu[4].subMenu;
        }
        if (menu.subMenu.length > 3) {
          this.userMenuList = menu.subMenu[3].subMenu;
        }
      }
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    });
  }

  userMenuItemClicked(clickMethod: string) {
    switch (clickMethod) {
      case 'logout':
        this.logout();
        break;
      default:
    }
    console.log('userMenuItemClicked', clickMethod);
  }

  logout() {
    console.log('logout clicked', this.userMenuList);
    this.logoutClicked.emit();
  }

  logClick(sys: string): void {
    this.loggerService.logPageVisit('top menu clicked', sys);
  }

  openYardi(): void {
    window.open('https://www.yardipca.com/91423cppt');
    this.loggerService.logPageVisit('top menu clicked', 'Yardi');
  }

  openYardiOne(): void {
    window.open('https://centerpoint39241.yardione.com');
    this.loggerService.logPageVisit('top menu clicked', 'YardiOne');
  }

  openOffice(): void {
    window.open('https://www.office.com');
    this.loggerService.logPageVisit('top menu clicked', 'MS 365');
  }

  openPowerBI(): void {
    window.open('https://app.powerbi.com/');
    this.loggerService.logPageVisit('top menu clicked', 'Power BI');
  }

  openSharePoint(): void {
    window.open('https://cntcloud.sharepoint.com');
    this.loggerService.logPageVisit('top menu clicked', 'Sharepoint');
  }

  openEgnyte(): void {
    window.open('https://cntcloud.egnyte.com');
    this.loggerService.logPageVisit('top menu clicked', 'Egnyte');
  }

  hideSearch(): void {
    this.searchService.closeSearch();
  }

  checkClose(term: string) {
    if (term.length === 0) {
      this.hideSearch();
    }
  }

  openSearch(): void {
    this.searchService.openSearch();
  }

  toggleSideMenu(): void {
    this.menuService.toggleSystemMenu(!this.showLeftMenu);
  }

  togglePoint(): void {
    this.menuService.togglePoint(!this.showPoint);
  }

  toggleMinMaxLeftMenu(): void {
    this.menuService.toggleMinMaxLeftMenu(!this.leftMenuIsCollapsed);
  }

  goHome() {
    this.router.navigate(['']);
  }

  configureDefaultBadDataTicket() {
    this.defaultBadDataTicket = new BadDataTicket();
    this.defaultBadDataTicket.assignees.push(new Assignee(Constants.PCKeyKevin, 'Kevin German', 'kgerman@centerpoint.com'));
    this.defaultBadDataTicket.assignees.push(new Assignee(Constants.PCKeyRick, 'Rick Okrasinski', 'rokrasinski@centerpoint.com'));
    this.defaultBadDataTicket.systemKey = Constants.BadDataSystemGeneral;
    this.defaultBadDataTicket.systemDesc = 'General';
    this.defaultBadDataTicket.location = '';
    this.badDataTicket = Object.assign({}, this.defaultBadDataTicket);
  }

  openBadDataTicket() {
    let dialogRef = this.dialog.open(BadDataTicketComponent, {
      disableClose: true,
      width: '675px',
      position: { top: '150px' },
      data: { ticket: this.badDataTicket }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.status === 'saved') {
        this.getBadDataCount();
      }
    });
  }

  openBadDataListing() {
    let dialogRef = this.dialog.open(BadDataComponent, {
      width: '1200px',
      position: { top: '100px' },
      autoFocus: false,
      data: { mode: this.badDataTicket.systemDesc, tableKey: this.getTicketObjectsKey() }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getBadDataCount();  // check if any were resolved?
    });
  }

  getBadDataCount() {
    // get the count based on the system/key
    if (this.badDataTicket.systemDesc === 'General') {
      this.badDataCount = 0;
    } else {
      this.badDataService.getTicketCount(this.badDataTicket.systemDesc, this.getTicketObjectsKey()).subscribe(result => {
        this.badDataCount = result;
      });
    }
  }

  getTicketObjectsKey(): number {
    let tKey = null;
    if (this.badDataTicket.systemDesc === 'Property') {
      tKey = this.badDataTicket.propertyKey;
    } else if (this.badDataTicket.systemDesc === 'Lease') {
      tKey = this.badDataTicket.leaseKey;
    }
    return tKey;
  }

  getUserMenu(): void {
    this.userService.userMenu$.subscribe((menu) => {
      if (menu != null) {
        this.userMenuList = menu.subMenu[3].subMenu;
      }
    });
  }
}

export class LeaseAbstract {
  abstractKey: number;
  leaseKey: number;
  itemKey: number;
  itemDesc: string;
  maintainKey: number;
  maintainDesc: string;
  repairKey: number;
  repairDesc: string;
  replaceKey: number;
  replaceDesc: string;
  itemNotes: string;

  constructor() {
    this.itemNotes = '';
  }
}

export class LeaseContractor {
  id: number;
  leaseKey: number;
  propertyKey: number;
  trade: string;
  tradeId: number;
  tradeName: string;
  company: string;
  contactName: string;
  phoneOffice: string;
  phoneExt: string;
  phoneMobile: string;
  email: string;
  title: string;
  lastInspection: string;
  cnt: string;
  notes: string;
  dirty: boolean;
  isContractor: boolean;

  constructor(
    id?: number,
    leaseKey?: number,
    trade?: string,
    company?: string,
    contactName?: string,
    phoneOffice?: string,
    phoneExt?: string,
    phoneMobile?: string,
    email?: string,
    title?: string,
    lastInspection?: string,
    cnt?: string,
    notes?: string,
    tradeName?: string
  ) {
    this.id = id;
    this.leaseKey = leaseKey;
    this.trade = trade;
    this.company = company;
    this.contactName = contactName;
    this.phoneOffice = phoneOffice;
    this.phoneExt = phoneExt;
    this.phoneMobile = phoneMobile;
    this.email = email;
    this.title = title;
    this.lastInspection = lastInspection;
    this.cnt = cnt;
    this.notes = notes;
    this.dirty = false;
    this.isContractor = true;
    this.tradeName = tradeName;
  }
}

import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { SearchResultPopupComponent } from '../core/search-result-popup/search-result-popup.component';
import { SideSubMenuComponent } from '../core/side-sub-menu/side-sub-menu.component';
import { AlertComponent } from '../core/alert/alert.component';
import { AuthService } from '../auth.service';
import { LeftMenuComponent } from '../core/left-menu/left-menu.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { TopMenuComponent } from '../core/top-menu/top-menu.component';
import { NgIf } from '@angular/common';
import { MenuService } from '../core/menu.service';
import { Constants } from '../core/constants';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'cub-layout-main',
  standalone: true,
  imports: [AlertComponent, SideSubMenuComponent, SearchResultPopupComponent, NgIf, TopMenuComponent, MatSidenavModule, LeftMenuComponent, RouterOutlet],
  templateUrl: './layout-main.component.html',
  styleUrl: './layout-main.component.scss',
    animations: [
        trigger('sideMenuLoadingState', [
            state('true', style({
                marginLeft: '-220px'
            })),
            state('false', style({
                marginLeft: '0'
            })),
            transition('true => false', animate('350ms ease-in')),
            transition('false => true', animate('350ms ease-out'))
        ])
    ],
})
export class LayoutMainComponent {

  showTopMenu: boolean;
  sideNavMode = 'side';
  sideNavOpened = false;
  screenWidth: number;

  constructor(
    public authService: AuthService,   // was already here
    private route: ActivatedRoute,
    private router: Router,
    private menuService: MenuService,
  ) {
    this.screenWidth = (window.innerWidth);
    if (this.screenWidth <= Constants.ResponsiveWidthTrigger) {
      this.menuService.toggleLeftMenu(false);
      this.menuService.toggleSystemMenu(false);
      this.sideNavMode = 'over';
    }
    this.menuService.showingTopMenu$.subscribe(result => {
      this.showTopMenu = result;
    });
  }

  ngOnInit(): void {
    // console.log('app component ngOnInit Firing');
    this.authService.updateLoggedInStatus(); // was already here

    this.menuService.showingLeftMenu$.subscribe(result => {
      this.sideNavOpened = result;
    });
    this.menuService.minMaxLeftMenu$.subscribe(result => {
      this.sideNavOpened = !this.sideNavOpened;
    });
  }

  getMarginTop(): number {
    return (this.showTopMenu ? 51 : -2);
  }

  logout() { // was already here
    this.authService.logout();
  }
}

export class LeaseAmendment {
  amendmentHmy: number;
  statusDesc: string;
  typeDesc: number;
  approvalStatus: string;
  modificationDesc: string;
  sequence: number;
  leaseSf: string;
  term: number;
  isMTM: boolean;
  startDt: string;
  endDt: string;
  amendmentDt: string;

  constructor() {
  }
}

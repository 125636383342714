
export class StraightLine {
  hTenant: number;
  hAmendment: number;
  adjustmentMonth: string;
  rentCharged: string;
  rentTotal: string;
  avgRent: string;
  adjustmentAmt: string;
  adjustmentTotal: string;
  amortizedAmt: string;
  postMonth: string;
  controlNumber: string;
  dtFrom: string;
  dtTo: string;
  notes: string;

  constructor() {
  }
}

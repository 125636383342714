export class LeaseLedger {
  transactionHmy: number;
  transactionType: number;
  transactionDt: string;
  transactionDesc: string;
  charges: string;
  payments: string;
  balance: string;
  unitID: string;

  constructor() {
  }
}

import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'cub-layout-simple',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './layout-simple.component.html',
  styleUrl: './layout-simple.component.scss'
})
export class LayoutSimpleComponent {

}

import { Unit } from '../property/unit';
import { User } from '../user/user';

import * as moment from 'moment';

export class LeaseEntry {
  leaseKey: number;
  leaseId: string;
  tenantPCKey: number;
  tenantName: string;
  leaseSF: string;
  startDt: Date;
  endDt: Date;
  signedDt: Date;
  moveInDt: Date;
  leaseTerm: number;
  isMTM: boolean;
  classKey: number;
  propertyKey: number;
  propertyId: string;
  propertyDesc: string;
  address: string;
  city: string;
  stateId: string;
  zipCode: string;
  rentTypeKey: number;
  dealMakerPCKey: number;
  sicCodeKey: string;
  dealNotes: string;
  otherNotes: string;
  directoryPath: string;
  creatorUID: string;
  creatorDt: Date;
  movedToYardiUID: string;
  movedToYardiDt: Date;
  lastUID: string;
  lastDt: Date;
  yardiHmy: number;
  yardiCommTenantHmy: number;
  yardiAmendmentHmy: number;
  saveStatus: string;
  utilityLanguageKey: number;
  industryClassificationKey: number;

  units: Unit[] = [];

  constructor() {
    this.leaseKey = 0;
    this.leaseId = '';
    this.tenantPCKey = 0;
    this.tenantName = '';
    this.leaseSF = '';
    this.startDt = null;
    this.endDt = null;
    this.signedDt = null;
    this.moveInDt = null;
    this.leaseTerm = 0;
    this.isMTM = false;
    this.classKey = 4005;
    this.propertyKey = 0;
    this.propertyId = '';
    this.propertyDesc = '';
    this.address = '';
    this.city = '';
    this.stateId = '';
    this.zipCode = '';
    this.rentTypeKey = 2;
    this.dealMakerPCKey = 0;
    this.sicCodeKey = '';
    this.dealNotes = 'Original Lease';
    this.otherNotes = '';
    this.directoryPath = '';
    this.creatorUID = '';
    this.creatorDt = null;
    this.movedToYardiUID = '';
    this.movedToYardiDt = null;
    this.lastUID = '';
    this.lastDt = null;
    this.yardiHmy = 0;
    this.yardiCommTenantHmy = 0;
    this.yardiAmendmentHmy = 0;
    this.saveStatus = '';
    this.utilityLanguageKey = 4;
    this.industryClassificationKey = 0;

    if (localStorage.getItem('user') !== null) {
      let me = JSON.parse(localStorage.getItem('user')) as User;
      this.otherNotes = 'Created with the CUB Lease Entry screen on ' + moment().format('MM/DD/YYYY') + ' by ' + me.fullName;
    }
  }
}

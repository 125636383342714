export class Utility {
  id: number;
  utilityTypeId: number;
  utilityTypeName: string;
  leaseKey: number;
  propertyKey: number;
  utility: string;
  company: string;
  meter: string;
  account: string;
  name: string;
  phone: string;
  notes: string;
  glCode: string;
  unitKeys: number[];
  leaseKeys: number[];
  dirty: boolean;

  constructor(
    id?: number,
    propertyKey?: number,
    utility?: string,
    utilityTypeId?: number,
    company?: string,
    meter?: string,
    account?: string,
    name?: string,
    phone?: string,
    notes?: string,
    glCode?: string,
    utilityTypeName?: string,
    unitKeys?: number[]) {
    if (id) {
      this.id = id;
    } else {
      this.id = 0;
    }
    this.utility = utility;
    this.utilityTypeId = utilityTypeId;
    this.company = company;
    this.meter = meter;
    this.name = name;
    this.account = account;
    this.phone = phone;
    this.notes = notes;
    this.glCode = glCode;
    this.propertyKey = propertyKey;
    this.dirty = false;
    this.utilityTypeName = utilityTypeName;
    if (unitKeys) {
      this.unitKeys = unitKeys;
    } else {
      this.unitKeys = [];
    }
    this.leaseKeys = [];
  }
}

export class OpenItem {
  transactionID: string;
  transactionDesc: string;
  transactionDt: string;
  chargeAmt: string;
  paymentAmt: string;
  netDueAmt: string;
  balanceAmt: string;

  constructor() {
  }
}

export class FollowUp {
  createdDt: Date;
  creatorUid: string;
  deadlineDt: Date;
  details: string;
  followUpDesc: string;
  id: number;
  lastDt: Date;
  lastFollowUpDt: Date;
  lastUid: string;
  leaseKey: number;
  letterSentDt: Date;
  name: string;
  nextFollowUpDt: Date;
  notes: string;
  statusId: number;
  typeId: number;
  done: boolean;

  static toFollowUp(json): FollowUp {
    return {
      createdDt: json.createdDt ? new Date(json.createdDt) : "",
      creatorUid: json.creatorUid,
      deadlineDt: json.deadlineDt ? new Date(json.deadlineDt) : "",
      details: json.details,
      followUpDesc: json.followUpDesc,
      id: json.id,
      lastDt: json.lastDt ? new Date(json.lastDt) : "",
      lastFollowUpDt: json.lastFollowUpDt ? new Date(json.lastFollowUpDt) : "",
      lastUid: json.lastUid,
      leaseKey: json.leaseKey,
      letterSentDt: json.letterSentDt ? new Date(json.letterSentDt) : "",
      name: json.name,
      nextFollowUpDt: json.nextFollowUpDt ? new Date(json.nextFollowUpDt) : "",
      notes: json.notes,
      statusId: json.statusId,
      typeId: json.typeId,
      done: json.done,
    } as FollowUp;
  }
}

import { Contact } from '../contact/contact';
import { Unit } from './unit';
export class Property {
  propertyId: string;
  propertyKey: number;
  propertyDesc: string;
  leasableSpace: number;
  city: string;
  stateId: string;
  zipCode: string;
  county: string;
  groupKey: number;
  typeKey: number;
  groupDesc: string;
  typeDesc: string;
  occupancy: number;
  propertyMgrName: string;
  propertyMgrEmail: string;
  regionalMgrName: string;
  regionalMgrEmail: string;
  salesBasis: number;
  tac: number;
  acqProspectKey: number;
  classDesc: string;
  entityDesc: string;
  subEntityDesc: string;
  certifiedDt: string;
  certifiedUID: string;
  isCertified: boolean;
  certificationUpdated: boolean;
  mapPath: string;
  aerialPath: string;
  webRegionKey: number;
  investmentTypeKey: number;
  investmentCategoryKey: number;
  commercialClassKey: number;
  strategicRelevanceKey: number;
  dealMakersOriginal: Contact[];
  dealMakersCurrent: Contact[];
  formerPropertyID: string;
  useType: string;
  siteDesc: string;
  purchasePrice: number;
  rehabBudgetAmt: number;
  initAnnualNOI: number;
  irr: number;
  bestCaseIRR: number;
  worstCaseIRR: number;
  salePriceSF: number;
  initialYield: number;
  actualYield: number;
  stableYield: number;
  cashOnCashYr1: number;
  cashOnCashYr2: number;
  cashOnCashYr3: number;
  cashOnCashYr4: number;
  cashOnCashYr5: number;
  noiPsf: number;
  vacantSpace: number;
  grossSpace: number;
  warehouseSpace: number;
  officeSpace: string;
  retailSpace: number;
  otherSpace: number;
  excludableSpace: number;
  siteSize: number;
  closingDt: string;
  soldDt: string;
  goFirmDt: string;
  contractDt: string;
  boardApprovalDt: string;
  icApprovalDt: string;
  insuranceInspectionDt: string;
  discussAtOps: boolean;
  aussieDeal: boolean;
  insuranceInspectionRequired: boolean;
  classKey: number;
  categoryKey: number;
  subEntityKey: number;
  zoningKey: number;
  addressKey: number;
  tenant: string;
  floodZone: string;
  seller: string;
  nickname: string;
  address1: string;
  stateKey: number;
  country: string;
  latitude: number;
  longitude: number;
  calEastID: string;
  areisID: string;
  creatorUID: string;
  creatorDt: string;
  lastUID: string;
  lastDt: string;
  buildingCount: number;
  unitCount: number;
  regionalMgrPcKey: number;
  propertyMgrPcKey: number;
  propAcctPcKey: number;
  maintenanceMgrPcKey: number;
  listingBrokerPcKey: number;
  cbSubmarketKey: number;
  cbkSubmarketKey: number;
  cushmanSubmarketKey: number;
  businessParkKey: number;
  entityKey: number;
  originKey: number;
  fundKey: number;
  listingExpirationDt: string;
  fileScanningSentDt: Date;
  fileScanningBoxNumber: string;
  fileScanningNotes: string;
  listingBrokerName: string;
  inServiceOccupancy: number;
  imagePath: string;
  imagePathLarge: string;
  sitePlanPath: string;
  sitePlanPathLarge: string;
  webURL: string;
  insuranceCoverage: number;
  liabilityCoverage: number;
  insuranceEffectiveDt: string;
  insuranceExpirationDt: string;
  liabilityEffectiveDt: string;
  liabilityExpirationDt: string;
  cbSubmarketDesc: string;
  cbkSubmarketDesc: string;
  cushmanSubmarketDesc: string;
  propertyNotes: string;
  class0Notes: string;
  yardiScode: string;
  yardiHMY: number;
  promotedDt: string;
  promotedUID: string;
  earnestMoney: number;
  stabilizedNOI: number;
  initialSL: number;
  closingPct: number;
  stabilizedSL: number;
  verifiedForPromoteDt: string;
  verifiedForPromoteUID: string;
  directoryPath: string;
  deptFileApproval: boolean;
  fileApprovalRiskMgt: boolean;
  tenantImprove: boolean;
  irrWithoutResidualGrowth: number;
  holdPeriod: number;
  sharepointWebName: string;
  isGroundLease: boolean;
  listOnWeb: boolean;
  energyStarCertified: boolean;
  leedCertificationKey: number;
  listForSale: boolean;
  groupAvailableUnits: boolean;
  lastProjectionDate: string;
  excessLandAcreage: string;
  additionalDevelopment: string;
  webNotes: string;
  cntMarketKey: number;
  landBuilding: number;
  customerLeaseValue: number;
  aboveBelowMarketLeases: number;
  acquisitionPrice: number;
  propertyImprovements: number;
  leaseBasis: number;
  costBasis: number;
  straightLine: number;
  netLeasingCosts: number;
  grossLeasingCosts: number;
  monthlyStraightLineRent: number;
  adjustedBasis: number;
  solsticeBasis: number;
  solsticeBasisSF: number;
  basisVerified: boolean;
  basisNotes: string;
  basisVerifiedDt: string;
  basisVerifiedUID: string;
  basisLastUpdateDt: string;
  basisLastUpdateUID: string;
  closingCostsPrior: number;
  closingCostsSince: number;
  natPropertyImprovements: number;
  natDepreciation: number;
  natLeaseBasis: number;
  natStraightLine: number;
  isNatBasis: boolean;
  gaapBasis: number;
  ffoBasis: number;
  newGrossSF: number;
  originalGrossSF: number;
  grossSFChangeNotes: string;
  grossSFChangedDt: string;
  grossSFChangedUID: string;
  budgetedGrossCamExpense: number;
  budgetedGrossTaxExpense: number;
  budgetedGrossInsExpense: number;
  budgetedExpenseYear: number;
  appraisalYear: number;
  appraisalQtr: number;
  appraisalAmt: number;
  externalAppraisalQuarter: number;
  isInOccupancyCalculation: boolean;
  isDepartment: boolean;
  legalEntity: string;
  assetRankingKey: number;
  assetRankingDesc: string;
  webName: string;
  externalWebLink: string;
  greenStreetRatingKey: number;
  greenStreetRatingKeyOriginal: number;
  estimatedCapRate: number;
  noiBudgeted: number;
  propAcctName: string;
  maintenanceMgrName: string;
  managementFirmKey: number;
  managementFirmDesc: string;
  sameStoreGrowth: number;
  askingRent: number;
  pctOccupied: number;
  encumbered: boolean;
  lender: string;
  arrayGRESB: string[];
  doors: number;
  brochurePath: string;
  isSameStore: boolean;
  sameStoreNotes: string;
  noiRolling: number;
  marketDesc: string;
  dispoRankingKey: number;
  dispoRankingDesc: string;
  partnershipNameKey: number;
  partnershipRiskKey: number;
  assetRiskKey: number;
  calpersTierKey: number;
  msaKey: number;
  videoUrl: string;
  owningEntityKey: number;
  regionKey: number;
  lifeCycleKey: number;
  dispoCandidateKey: number;
  isLeasedLand: boolean;
  logisticalClassificationKey: number;
  constructionMgrPcKey: number;
  constructionMgrFirstName: string;
  constructionMgrLastName: string;
  constructionMgrName: string;
  portCentric: boolean;
  ownershipPct: number;
  grossAcres: number;
  netAcres: number;
  propMgrFirstName: string;
  propMgrLastName: string;
  regMgrFirstName: string;
  regMgrLastName: string;
  noiYtd: number;
  constructionStartDt: Date;
  constructionEndDt: Date;
  landClosingDt: Date;
  reportingStartDt: Date;
  reportingEndDt: Date;
  pctLeased: number;
  landPropertyKey: number;
  devDealMakers: number[];
  fiscalYear: number;
  leaseCount: number;
  leaseCountForOcc: number;
  partnershipRiskDesc: string;
  inLastCompletedDataBucket: boolean;
  inCurrentTempDataBucket: boolean;
  yearBuilt: string;
  lastRehabYear: string;
  assetAge: string;
  clearHt: string;
  craneCount: number;
  docks: number;
  driveInDoors: number;
  carSpace: number;
  trailerSpace: number;
  exteriorTypeDesc: string;
  roofType: string;
  powerDesc: string;
  invTypeDesc: string;
  tenantList: string;
  levels: string;
  anchorTenantExpirationDt: string;
  landZoneDesc: string;
  commercialClassDesc: string;
  greenStreetRatingDesc: string;
  categoryDesc: string;
  geoRegionDesc: string;
  calpersTierDesc: string;
  assetRiskDesc: string;
  lifecycleDesc: string;
  calpersEntityName: string;
  partnershipNameDesc: string;
  ncreifMarket: string;
  ncreifSubMarket: string;
  appraisalFromDataBucket: number;
  appraisalProRata: number;
  noiCurrent: number;
  proRataShareValue: number;
  coldStorageSf: number;
  truckCourtDepth: string;
  calpersMarketKey: number;
  railProximity: boolean;
  logisticalClassDesc: string;
  availableOnWeb: boolean;
  consolidateIntoPropertyId: string;
  consolidatedatedFromList: string;
  ncreifAreisId: string;
  ncreifGrossSalePrice: number;
  ncreifNetSalePrice: number;
  fairMarketValue: number;
  totalLeasableSf: number;

  // dev pipe fields
  devArchived: boolean;
  devOnPipeline: boolean;
  devCopyFiles: boolean;
  devPhaseKey: number;
  devTypeKey: number;
  devParkKey: number;
  devCostBasis: number;
  devFolderPath: string;
  devTenYrLevered: number;
  devTenYrUnlevered: number;
  devTrendedRoc: number;
  devMgrKey: number;
  devMgrName: string;
  calpersPropertyType: number;
  calpersPropertySubType: number;
  greenStreetMarket: string;
  greenStreetSubMarket: string;
  hasSolar: boolean;
  hasFirePermit: boolean;
  hasStormWaterPermit: boolean;
  firePermitExpirationDt: string;
  hasCertificateOfCompliance: boolean;
  salesforceId: string;
  unitTypeSummary: string;

  leasableAcres: number;
  nonLeasableAcres: number;
  itemCount: number;
  spotCount: number;

  // field used only locally - don't exist on server
  activeItem: boolean;
  scrollIndex: number;
  sameStoreNOICurrentYear: number;
  sameStoreNOIPrevYear: number;
  sameStorePeriodEndDt: Date;
  noiBudget: number;
  budgetLeft: number; // land and cip schedules
  budgetTotalDraws: number; // land and cip schedules
  totatEstBudget: number; // land and cip schedules
  pctFunded: number; // land and cip schedules

  // Corp Entity System
  corporateEntityName: string;
  corporateEntityKey: number;

  //Kevin - in progress
  yardiBudgetKey: number;
  devActive: boolean;

  // energy star stuff (cac)
  espmId: string;  // energy star portfolio manager id.

  constructor() {
    this.fileScanningSentDt = null;
    this.constructionStartDt = null;
    this.constructionEndDt = null;
    this.landClosingDt = null;
  }
}

export class PropertyBudgetLink {
  propertyKey: number;
  budgetKey: number;

  constructor() { }
}

export class OccupancyHistory {
  propertyKey: number;
  period: string;
  fiscalPeriod: string;
  leasableSF: number;
  occupancy: number;

  constructor() { }
}

export class QuarterlyAppraisal {
  propertyKey: number;
  year: number;
  qtr: number;
  appraiser: string;
  acres: number;
  squareFeet: number;
  amount: number;
  amountPerSF: number;
  fmvAmount: number;
  fmvAmountPerSF: number;

  constructor() { }
}

export class LeaseSummary {
  unitKey: number;
  unitId: string;
  unitDesc: string;
  leaseKey: number;
  leaseId: string;
  tenantName: string;
  effectiveDt: string;
  expirationDt: string;
  leaseTerm: string;
  classKey: number;
  leaseStatus: number;
  leaseStatusDesc: string;
  leaseSF: string;
  leasableSF: string;
  netRentSF: number;
  arAging030: number;
  arAging3160: number;
  arAging6190: number;
  arAging91Plus: number;

  // not defined at server
  arAgingTotal: number;
  reExecKey: number;
  onSiteMgrKey: number;
  onSiteMgrName: string;
  reExecName: string;
  securityDepositAmt: string;
  loc: string;
  leasePctComplete: number;
  units: Unit[];
  propertyId: string;

  constructor(tenantName?: string, arAgingTotal?: number) {
    this.units = [];
  }
}

export class VacancyOutlook {
  year: string;
  expiringSF: number;
  mtmSF: number;
  termOptionSF: number;
  specSF: number;
  dealSF: number;

  constructor(yr: string) {
    this.year = yr;
    this.expiringSF = 0;
    this.mtmSF = 0;
    this.termOptionSF = 0;
    this.specSF = 0;
    this.dealSF = 0;
  }
}

export class SameStoreSummary {
  propertyKey: number;
  hasSameStore: boolean;
  currentNOI: number;
  previousNOI: number;
  sameStoreGrowth: number;
  sameStoreNormalized: number;
  fiscalYr: number;
  lastDt: Date;
  currentEndDt: Date;
  noiYtd: number;
  noiBudget: number;

  constructor() { }
}

export class PropertyAccountTree {
  acctHmy: number;
  acctCode: string;
  acctDesc: string;
  acctType: string;
  totalIntoHmy: number;
  underline: number;
  negate: number;
  ytd: number;
  ytdCompare: number;
  remove: boolean;
  lastDt: Date;

  // used locally
  indent: number;

  constructor() { }
}

export class SpaceChangeLog {
  originalSF: number;
  newSF: number;
  reason: string;
  userName: string;
  changeDt: string;

  constructor() { }
}

export class LeasingActivity {
  propertyId: string;
  leaseId: string;
  type: string;
  startDt: string;
  endDt: string;
  activatedDt: string;
  activatedBy: string;
  leasedSF: number;
  tenantName: string;

  constructor() { }
}

export class MasterEditField {
  name: string;
  dbFieldName: string;
  jsFieldName: string;
  fieldType: string;
  format: string;
  required: boolean;
  masterTableName: string;
  masterTablePK: string;
  masterTableDesc: string;
  masterTableWhereClause: string;

  constructor() { }
}
export class MasterEditFormat {
  propertyFields: MasterEditField[];
  buildingFields: MasterEditField[];
  unitFields: MasterEditField[];

  constructor() {
    this.propertyFields = [];
    this.buildingFields = [];
    this.unitFields = [];
  }
}
export class PropertyAcre {
  pKey: number;
  propertyKey: number;
  grossAcres: number;
  netAcres: number;
  effectiveDt: string;
  notes: string;
  lastDt: string;
  lastUid: string;

  constructor() { }
}

export class PropertyAdvance {
  advanceType: number;
  propertyKey: number;
  propertyCategoryDesc: string;
  propertyCategoryKey: number;
  propertyCategoryKeyNew: number;
  isRename: boolean;
  propertyId: string;
  propertyIdNew: string;
  propertyDesc: string;
  propertyDescNew: string;
  directoryPath: string;
  directoryPathNew: string;
  isReclass: boolean;
  propertyHmy: number;
  jobHmy: number;
  acctHmy: number;
  lastUid: string;

  constructor() { }
}

export class DueDiligenceJobs {
  propertyKey: number;
  propertyHmy: number;
  propertyId: string;
  propertyDesc: string;
  jobHmy: number;
  jobCode: string;
  jobDesc: string;
  jobStatus: string;
  jobType: string;

  constructor() { }
}

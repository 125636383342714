import { Unit } from '../property/unit';
import { LeaseAbstract } from './leaseAbstract';
import { LeaseAmendment } from './leaseAmendments';
import { LeaseCharge } from './leaseCharge';
import { LeaseOption } from './leaseOption';
import { Contact } from '../contact/contact';

export class Lease {
  maintAbstract: LeaseAbstract[];
  options: LeaseOption[];
  charges: LeaseCharge[];
  amendments: LeaseAmendment[];
  terminations: LeaseTerminationOption[];
  units: Unit[] = [];
  relationships: Contact[];
  leaseID: string;
  leaseKey: number;
  tenantName: string;
  effectiveDt: string;
  expirationDt: string;
  baseRentSF: string;
  leaseSF: string;
  propertyDesc: string;
  propertyID: string;
  propertyKey: number;
  city: string;
  stateId: string;
  zipCode: string;
  meterDoor: string;
  gate: string;
  fireNumber: string;
  policeNumber: string;
  tenantNotes: string;
  alarmFireMonitor: string;
  alarmFirePwd: string;
  alarmFirePanel: string;
  alarmFireAcct: string;
  alarmFireCall1: string;
  alarmFireCall2: string;
  alarmBurglarMonitor: string;
  alarmBurglarPwd: string;
  alarmBurglarPanel: string;
  alarmBurglarAcct: string;
  alarmBurglarCall1: string;
  alarmBurglarCall2: string;
  ismtm: boolean;
  groupKey: number;
  hasMgmtFee: boolean;
  mgmtFeePct: string;
  abstractNotes: string;
  leaseClassDesc: string;
  leaseTypeDesc: string;
  executedDt: string;
  terminationDt: string;
  originTypeDesc: string;
  categoryTypeDesc: string;
  leaseSfNotEqualUnitSf: boolean;
  truckStalls: number;
  billToAddress: string;
  tenantNameOverride: string;
  dealNotes: string;
  otherNotes: string;
  sicCodeKey: number;
  dealMakerPCKey: number;
  dealMakerName: string;
  noticesContactKey: number;
  noticesContact2Key: number;
  noticesContact3Key: number;
  noticesContactName: string;
  noticesContact2Name: string;
  noticesContact3Name: string;
  reExecKey: number;
  reExecName: string;
  onSiteMgrKey: number;
  onSiteMgrName: string;
  icApprovalNum: string;
  icApprovalDt: string;
  finalApprovalNum: string;
  finalApprovalDt: string;
  creatorUID: string;
  creatorDt: string;
  lastUID: string;
  lastDt: string;
  movedToYardiUID: string;
  movedToYardiDt: string;
  securityDepositAmt: string;
  loc: string;
  propertyImagePath: string;
  groupName: string;
  daysRemaining: number;
  propertyCategoryKey: number;
  directoryPath: string;
  arAging030: number;
  arAging3160: number;
  arAging6190: number;
  arAging91Plus: number;
  askingRent: number;
  utilityLanguageKey: number;
  regionalMgrKey: number;
  propertyMgrKey: number;
  propertyAcctKey: number;
  propMgrEmail: string;
  customerTypeKey: number;
  activityTypeKey: number;
  ecommerceStatus: number;
  retailUtilizeAmazon: boolean;
  industryClassificationKey: number;
  replacementTypeKey: number;
  leasePredecessorKey: number;
  countCashStartDt: Date;
  countCashEndt: Date;
  maintenanceLanguageSection: string;
  certificateOfCompliance: boolean;
  occupancyType: string;

  tenantElectricIntervalInfo?: boolean;
  loaExpirationDt?: Date;
  isElectricitySpendInCntControl?: boolean;
  tenantElectricSupplyComments?: string;
  cntHasRightsToInstallSolar?: boolean;
  cntSolarInstallRightsNote?: string;
  cntHasRightsToSellSolar?: boolean;
  cntSolarSellingNote?: string;
  cntIsResponsibleForRoofMaintenance?: boolean;
  cntRoofMaintenanceNote?: string;

  // listing only
  propMgrFullName: string;
  leaseClassKey: number;

  marketDesc: string;
  groupDesc: string;
  typeDesc: string; // prop type
  county: string;  // needs to get it from prop

  // field used only locally - don't exist on server
  activeItem: boolean;
  scrollIndex: number;
  topTenantSF: number;
  topTenantRent: number;
  arAgingTotal: number;
  parentCompanyKey: number;
  parentCompanyName: string;
  netRentSf: number;
  leasableSf: string;
  pctComplete: number;

  constructor(id?: string) {
    if (id) {
      this.maintAbstract = [];
      this.options = [];
      this.amendments = [];
      this.charges = [];
      this.terminations = [];
      this.relationships = [];
      this.leaseID = id;
    }

    this.tenantName = '';
  }
}

export class LeaseTerminationOption {
  leasSF: number;
  baseRent: number;
  annualRent: number;
  optionYr: number;

  constructor() { }
}

export class ContactListItem {
  type: string;
  typeKey: number;
  contact: Contact;
  leaseKey: number;
  propertyKey: number;

  constructor(t: string, tKey: number, c: Contact, leaseKey: number, propertyKey?: number) {
    this.type = t;
    this.typeKey = tKey;
    this.contact = c;
    this.leaseKey = leaseKey;
    if (propertyKey) {
      this.propertyKey = propertyKey;
    }
  }
}

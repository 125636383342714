export class LeaseCharge {
  leaseKey: number;
  leaseID: number;
  chargeCodeKey: number;
  chargeCodeId: string;
  chargeCodeDesc: string;
  chargeTerm: string;
  startDt: string;
  endDt: string;
  chargeAmt: string;
  netRentAmt: string;
  chargeFrequency: string;
  chargeType: string;
  chargeFilterType: string;
  lastUID: string;
  lastDt: string;
  amendmentHmy: number;
  optionHmy: number;

  constructor() {
  }
}

<button mat-icon-button
        [matMenuTriggerFor]="submenu"
        class="filterButton">
  <fa-icon [icon]="faPlus" style="font-size: 1.0rem;" [fixedWidth]="true"></fa-icon>
</button>
<mat-menu #submenu="matMenu"
          [yPosition]="'below'"
          [xPosition]="'before'"
          [overlapTrigger]="false"
          style="margin-top: 5px;">
  <cub-new-menu-item *ngFor="let item of menuList"
                     [menuItem]="item"></cub-new-menu-item>
</mat-menu>

export class LeaseContact {
  id: number;
  leaseKey: number;
  contactName: string;
  phoneOffice: string;
  phoneExt: string;
  phoneMobile: string;
  email: string;
  title: string;
  notes: string;
  dirty: boolean;
  isContractor: boolean;

  constructor(
    id?: number,
    leaseKey?: number,
    contactName?: string,
    phoneOffice?: string,
    phoneExt?: string,
    phoneMobile?: string,
    email?: string,
    title?: string,
    notes?: string
  ) {
    this.id = id;
    this.leaseKey = leaseKey;
    this.contactName = contactName;
    this.phoneOffice = phoneOffice;
    this.phoneExt = phoneExt;
    this.phoneMobile = phoneMobile;
    this.email = email;
    this.title = title;
    this.notes = notes;
    this.dirty = false;
    this.isContractor = false;
  }


}

export class LeaseCertificate {
  certificateKey: number;
  leaseKey: number;
  certificateNotes: string;
  passThruNotes: string;
  sentToEbixDt: Date;
  legalEntity: string;
  lastUID: string;
  lastDt: Date;

  constructor(id?: string) {
    this.certificateKey = 0;
    this.leaseKey = 0;
    this.certificateNotes = '';
    this.passThruNotes = '';
    this.sentToEbixDt = null;
    this.legalEntity = '';
    this.lastUID = '';
    this.lastDt = null;
  }
}

export class LeaseOption {
  leaseKey: number;
  optionKey: number;
  optionType: string;
  startDt: string;
  endDt: string;
  noticeDt: string;
  optionTerm: string;
  optionSf: string;
  purchaseAmt: string;
  expirationDt: string;
  briefDesc: string;
  notes: string;

  constructor() {
  }
}
